import React from "react";
import "../Styles/MainContacts.css";
import { Container, Row, Col } from "react-bootstrap";

const MainContacts = () => {
  return (
    <div className="container my-5">
      <div
        className="contact-form border border-3 rounded-3 p-5 mx-auto"
        style={{ maxWidth: "900px", borderColor: "#800000" }}
      >
        <h1 className="text-center mb-2">Напишіть, якщо залишились питання</h1>
        <p className="text-center mb-4">
          Ми завжди раді допомогти вам у робочий час
        </p>
        <form>
          <div className="row align-items-center">
            <div className="col-md-4 mb-3 mb-md-0">
              <input
                type="text"
                className="form-control shadow-sm"
                placeholder="📞 Номер телефону"
              />
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <input
                type="email"
                className="form-control shadow-sm"
                placeholder="✉️ E-mail"
              />
            </div>
            <div className="col-md-4 text-md-start">
              <button type="submit" className="btn submit-btn w-100">
                Надіслати дані
              </button>
            </div>
          </div>
          <div className="form-check text-center mt-4">
            <div className="d-flex justify-content-center align-items-center">
              <input
                type="checkbox"
                className="form-check-input me-2"
                id="agreement"
                required
              />
              <label
                className="form-check-label small text-muted"
                htmlFor="agreement"
              >
                Натискаючи кнопку, ви погоджуєтесь з умовами обробки
                персональних даних
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MainContacts;
