// PopUp1.js
import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/PopUp1.css"; // Импортируем стили для попапа
import PopUpImg1 from "../Images/PopUpImg1.png";

const PopUp1 = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      className="custom-popup"
      dialogClassName="custom-popup-dialog"
    >
      <Modal.Body className="popup-body p-4">
        <Button
          variant="close"
          onClick={handleClose}
          aria-label="Close"
          className="close-button position-absolute top-0 end-0 m-3"
          style={{
            color: "#800000" /* Бордовый цвет крестика */,
            backgroundColor: "transparent" /* Убираем фон */,
            border: "none" /* Убираем границу */,
          }}
        />

        <div className="popup-content row">
          {/* Левая колонка с изображением */}
          <div className="popup-image col-md-6 mb-3 mb-md-0">
            <img src={PopUpImg1} className="img-fluid" alt="Рубашка 'Модерн'" />
          </div>
          {/* Правая колонка с текстом */}
          <div className="popup-text col-md-6">
            <h2 className="popup-title mb-3">
              Рубашка "<span className="popup-title-word">Модерн</span>"
            </h2>
            <p className="popup-description">
              Ця елегантна рубашка виконана з м'якої, дихаючої тканини, яка
              дарує відчуття комфорту на весь день. Ідеально підходить як для
              офіційних зустрічей, так і для повсякденного носіння. Класичний
              крій і сучасний дизайн створюють стильний і впевнений образ.
              Доступна в різних кольорах для вашого унікального стилю.
            </p>
            <div className="popup-info row mt-4">
              <div className="info-item col-6">
                <h5 className="info-number">01</h5>
                <p className="info-text">
                  Рубашки виникли в середньовічній Європі як нижня білизна,
                  служачи основою для складних нарядів.
                </p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">02</h5>
                <p className="info-text">
                  Білу рубашку вважають символом статусу і стилю,
                  популяризованим Джеймсом Бондом.
                </p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">03</h5>
                <p className="info-text">
                  Існує безліч кроїв, від оверсайз до приталених, для будь-якого
                  стилю.
                </p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">04</h5>
                <p className="info-text">
                  Сучасні рубашки можуть бути стійкими до зморшок і волого
                  відведення.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopUp1;
