import "./App.css";
import Header from "./Components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import MainSlide1 from "./Pages/MainSlide1";
import MainCatalog from "./Pages/MainCatalog";
import MainAboutUs from "./Pages/MainAboutUs";
import MainAdress from "./Pages/MainAdress";
import MainCarousel from "./Pages/MainCarousel";
import MainGallery from "./Pages/MainGallery";
import MainContacts from "./Pages/MainContacts";
import Footer from "./Components/Footer";

function App() {
  return (
    <>
      <Header />
      <MainSlide1 />
      <MainCatalog />
      <MainAboutUs />
      <MainAdress />
      <MainCarousel />
      <MainGallery />
      <MainContacts />
      <Footer />
    </>
  );
}

export default App;
