import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "../Styles/Header.css";
import { IoIosMail } from "react-icons/io";
import { PiMapPinFill } from "react-icons/pi";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FiMenu } from "react-icons/fi"; // Иконка меню

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <Container>
      <header className="header">
        <div className="header-left">
          <div className="circle">F</div>
          <a href="#home" className="name-header-text">
            Fashion Fusion
          </a>
        </div>
        <nav className={`header-nav ${menuOpen ? "open" : ""}`}>
          <ul className="nav-list">
            <li className="info-block">
              <IoIosMail />
              <a href="#mail" className="info-header-text">
                FashionFusion@gmail.com
              </a>
            </li>
            <li className="info-block">
              <PiMapPinFill />
              <a href="#street" className="info-header-text">
                м. Харків, <br /> пр. Незалежності 7
              </a>
            </li>
            <li className="info-block">
              <BiSolidPhoneCall />
              <a href="#contact" className="info-header-contact-text">
                <span className="telefon-header-text">+38 (097) 777-77-77</span>
                <br /> Щоденно з 9:00 до 20:00
              </a>
            </li>
          </ul>
        </nav>
        <button className="menu-toggle" onClick={toggleMenu}>
          <FiMenu />
        </button>
      </header>
      {menuOpen && (
        <div className="dropdown-menu">
          <ul className="nav-list-mobile">
            <li className="info-block">
              <IoIosMail />
              <a href="#mail" className="info-header-text">
                FashionFusion@gmail.com
              </a>
            </li>
            <li className="info-block">
              <PiMapPinFill />
              <a href="#street" className="info-header-text">
                м. Харків, <br /> пр. Незалежності 7
              </a>
            </li>
            <li className="info-block">
              <BiSolidPhoneCall />
              <a href="#contact" className="info-header-contact-text">
                <span className="telefon-header-text">+38 (097) 777-77-77</span>
                <br /> Щоденно з 9:00 до 20:00
              </a>
            </li>
          </ul>
        </div>
      )}
    </Container>
  );
};

export default Header;
