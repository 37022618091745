import React from "react";
import { Container, Row, Col } from "react-bootstrap"; // Импортируем Container из Bootstrap
import "../Styles/MainAboutUs.css"; // Подключаем файл CSS для стилей
import VectorAboutUs from "../Images/VectorAboutUs.png";

const MainAboutUs = () => {
  return (
    <Container>
      <Row className="AboutUsSection">
        <Col className="text-info-about-us">
          <h2 className="main-about-us-mini-title">Fashion Fusion</h2>
          <h1 className="main-about-us-title">
            Чому варто обирати саме наш магазин ?
          </h1>
          <ul>
            <li className="about-us-info">
              Унікальний дизайн, що підкреслює вашу індивідуальність і створює
              впізнаваний стиль.
            </li>
            <li className="about-us-info">
              Висока якість матеріалів забезпечує тривале використання та
              комфорт при носінні.
            </li>
            <li className="about-us-info">
              Доступні ціни дозволяють отримати найкраще без компромісів у
              якості товарів.
            </li>
          </ul>
          <button className="main-about-us-button">Детальніше про нас</button>
        </Col>
        <Col className="position-relative">
          <img
            src={VectorAboutUs}
            alt="About Us Image"
            className="main-about-us-img"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MainAboutUs;
