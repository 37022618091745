import React from "react";
import { Container, Row, Col } from "react-bootstrap"; // Импортируем Container из Bootstrap
import "../Styles/MainAdress.css"; // Подключаем файл CSS для стилей
import AdressMap from "../Images/AdressMap.png";

const MainAdress = () => {
  return (
    <Container>
      <Row className="adress-section">
        <Col className="position-relative">
          <img
            src={AdressMap}
            alt="Adress Map Image"
            className="main-adress-img"
          />
        </Col>
        <Col className="text-info-adress">
          <h2 className="main-adress-mini-title">Fashion Fusion</h2>
          <h1 className="main-adress-title">
            Наш магазин знаходиться в самому сердці Харкова
          </h1>
          <p className="main-adress-text">
            Наша адреса: <br />
            Проспект Незалежності 7
          </p>
          <button className="main-adress-button">Перейти до Google Maps</button>
        </Col>
      </Row>
    </Container>
  );
};

export default MainAdress;
