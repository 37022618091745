// PopUp1.js
import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/PopUp1.css"; // Импортируем стили для попапа
import PopUpImg2 from "../Images/PopUpImg2.png";

const PopUp2 = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      className="custom-popup"
      dialogClassName="custom-popup-dialog"
    >
      <Modal.Body className="popup-body p-4">
        <Button
          variant="close"
          onClick={handleClose}
          aria-label="Close"
          className="close-button position-absolute top-0 end-0 m-3"
        />
        <div className="popup-content row">
          {/* Левая колонка с изображением */}
          <div className="popup-image col-md-6 mb-3 mb-md-0">
            <img src={PopUpImg2} className="img-fluid" alt="Рубашка 'Модерн'" />
          </div>
          {/* Правая колонка с текстом */}
          <div className="popup-text col-md-6">
            <h2 className="popup-title mb-3">
              Штани "<span className="popup-title-word">Модерн</span>"
            </h2>
            <p className="popup-description">
              Стильні штани з м'якої тканини, що забезпечують комфорт та свободу
              рухів. Ідеально підходять для повсякденного носіння та активного
              відпочинку. Завдяки сучасному дизайну, ці штани легко поєднуються
              з різними верхами, створюючи стильний образ.
            </p>
            <div className="popup-info row mt-4">
              <div className="info-item col-6">
                <h5 className="info-number">01</h5>
                <p className="info-text">
                  Штани використовувалися ще в III тисячолітті до н.е. для
                  верхової їзди.
                </p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">02</h5>
                <p className="info-text">
                  Штани виготовляються з деніму, котону та різних синтетичних
                  волокон для зносостійкості.
                </p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">03</h5>
                <p className="info-text">
                  Популярні варіанти — чінос, джоггери та класичні брюки.
                </p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">04</h5>
                <p className="info-text">
                  Джинси стали символом молодіжної культури в 1950-х роках.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopUp2;
