import React, { useState, useEffect, useRef } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import "../Styles/MainCarousel.css";
import Person1 from "../Images/Person1.png";
import Person2 from "../Images/Person2.png";
import Person3 from "../Images/Person3.png";
import { ImQuotesLeft } from "react-icons/im";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const MainCarousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const carouselRef = useRef(null);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const reviews = [
    {
      name: "Вадим Федоров",
      city: "Харків",
      reviewMain:
        "«Fashion Fusion –  це місце, де мода стає доступною для кожного!»",
      review1:
        "Я не можу висловити словами, наскільки я задоволен покупками в 'Fashion Fusion'! Це справжня знахідка для тих, хто цінує якісний одяг та стиль.",
      review2:
        "Кожна річ, яку я придбав, була виготовлена з увагою до деталей і з високоякісних матеріалів.",
      review3: "Читати відгук повністю",
      class: "review1",
      imageSrc: Person1,
      imageAlt: "Вадим Федоров",
    },
    {
      name: "Олена Хамолі",
      city: "Одеса",
      reviewMain: "«Це місце, де я зможу знайти свій стиль!»",
      review1:
        "Нещодавно відкрила для себе 'Fashion Fusion' і просто закохалася в цей магазин!",
      review2: " Я придбала кілька футболок, які виглядають просто неймовірно.",
      review3: "Читати відгук повністю",
      class: "review2",
      imageSrc: Person2,
      imageAlt: "Олена Хамолі",
    },
    {
      name: "Каріна Лапіна",
      city: "Київ",
      reviewMain: "«Я відчула себе важливою клієнткою!»",
      review1:
        "Я нещодавно відвідала 'Fashion Fusion', і враження залишилися тільки позитивні!",
      review2:
        "Магазин має чудовий асортимент одягу, який підходить для будь-якого випадку.",
      review3: "Читати відгук повністю",
      class: "review3",
      imageSrc: Person3,
      imageAlt: "Каріна Лапіна",
    },
  ];

  return (
    <>
      <h1 className="carousel-title-main">
        Відгуки наших задоволених клієнтів про товар
      </h1>
      <Carousel
        className="mainContainer"
        interval={null}
        id="carouselExample"
        ref={carouselRef} // добавляем реф к карусели
      >
        {reviews.map((item, index) => (
          <Carousel.Item key={index}>
            <div
              className={`d-block w-100 position-relative imgBG2 ${item.class}`}
            />
            <Container className="position-absolute top-50 start-50 translate-middle text-center titleMain">
              <Row className="justify-content-center block-carousel">
                <Col xs={12} md={6} className="info-comm-carousel">
                  <p className="carousel-title">
                    <ImQuotesLeft />
                    <br />
                    {item.reviewMain}
                  </p>
                  <p className="carousel-text">{item.review1}</p>
                  <p className="carousel-text">{item.review2}</p>
                  <p className="carousel-text-reference">{item.review3}</p>
                </Col>
                <Col xs={12} md={6} className="text-center">
                  <img
                    src={item.imageSrc}
                    alt={item.imageAlt}
                    className="review-image"
                  />
                  <h3 className="carousel-text-name">{item.name}</h3>
                  <h5>{item.city}</h5>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="carousel-controls">
        <IoIosArrowBack
          className="carousel-arrow left-arrow"
          onClick={() => carouselRef.current.prev()} // используем метод prev
        />
        <IoIosArrowForward
          className="carousel-arrow right-arrow"
          onClick={() => carouselRef.current.next()} // используем метод next
        />
      </div>
    </>
  );
};

export default MainCarousel;
