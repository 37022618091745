import React from "react";
import { Container, Row, Col } from "react-bootstrap"; // Импортируем Container из Bootstrap
import "../Styles/MainSlide1.css"; // Подключаем файл CSS для стилей
import ImageSlide1 from "../Images/ImageSlide1.png";
import VectorBGSlide1 from "../Images/VectorBGSlide1.png";

const MainSlide1 = () => {
  return (
    <Container>
      <Row className="bg-main-slide-1">
        <Col className="text-info-slide1">
          <h2 className="main-slide-1-mini-title">Fashion Fusion</h2>
          <h1 className="main-slide-1-title">
            Стиль для кожного моменту вашого життя!
          </h1>
          <p className="main-slide-1-text">
            Ваш стильний вибір для кожного дня! Тут ви знайдете унікальні речі,
            що підкреслять вашу індивідуальність.
          </p>
          <button className="main-slide-1-button">Зробити замовлення</button>
        </Col>
        <Col className="position-relative">
          <img
            src={ImageSlide1}
            alt="Main Image"
            className="main-slide-1-img"
          />
          <img
            src={VectorBGSlide1}
            alt="Background Vector"
            className="bg-vector-img"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MainSlide1;
