import React from "react";
import "../Styles/MainGallery.css";
import "bootstrap/dist/css/bootstrap.min.css";
import photo1 from "../Images/photo1.png";
import photo2 from "../Images/photo2.png";
import photo3 from "../Images/photo3.png";
import photo4 from "../Images/photo4.png";
import photo5 from "../Images/photo5.png";
import photo6 from "../Images/photo6.png";
import photo7 from "../Images/photo7.png";
import photo8 from "../Images/photo8.png";
import photo9 from "../Images/photo9.png";

const MainGallery = () => {
  const photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
  ];

  return (
    <div className="main-gallery">
      <h1 className="main-gallery-title text-center">
        Дивіться фото наших задоволених клієнтів
      </h1>
      <h3 className="main-gallery-mini-title text-center">
        Фото клієнтів з їх instagram
      </h3>
      <div className="gallery-container">
        {photos.map((photo, index) => (
          <div className="gallery-item" key={index}>
            <img
              src={photo}
              alt={`Gallery item ${index + 1}`}
              className="gallery-img-item"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainGallery;
