import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Styles/MainCatalog.css";
import item1 from "../Images/item1.png";
import item2 from "../Images/item2.png";
import item3 from "../Images/item3.png";
import PopUp1 from "../Components/PopUp1"; // Импортируем компонент попапа 1
import PopUp2 from "../Components/PopUp2"; // Импортируем компонент попапа 2
import PopUp3 from "../Components/PopUp3"; // Импортируем компонент попапа 3

const MainCatalog = () => {
  const [showPopUp, setShowPopUp] = useState(null); // Состояние для хранения активного попапа

  const handleShowPopUp = (popUpNumber) => setShowPopUp(popUpNumber); // Открытие попапа по номеру
  const handleClosePopUp = () => setShowPopUp(null); // Закрытие попапа

  return (
    <Container>
      <h1 className="catalog-title">Каталог товарів</h1>
      <Row>
        <Col>
          <div className="catalog-item">
            <img src={item1} alt="Product 1" />
            <div className="catalog-info">
              <h3>Рубашка “Модерн”</h3>
              <p className="catalog-item-text">
                Стильна рубашка для кожного дня, підкреслить вашу
                індивідуальність.
              </p>
              <p className="catalog-item-price">Ціна: 175₴</p>
              <button onClick={() => handleShowPopUp(1)}>Детальніше</button>
            </div>
          </div>
        </Col>
        <Col>
          <div className="catalog-item">
            <img src={item2} alt="Product 2" />
            <div className="catalog-info">
              <h3>Штани “Модерн”</h3>
              <p className="catalog-item-text">
                Елегантні штани для сучасного образу, ідеальні для офісу та
                прогулянок.
              </p>
              <p className="catalog-item-price">Ціна: 175₴</p>
              <button onClick={() => handleShowPopUp(2)}>Детальніше</button>
            </div>
          </div>
        </Col>
        <Col>
          <div className="catalog-item">
            <img src={item3} alt="Product 3" />
            <div className="catalog-info">
              <h3>Футболка “Модерн”</h3>
              <p className="catalog-item-text">
                Комфортна футболка для активного стилю, зробить ваш день ще
                зручнішим.
              </p>
              <p className="catalog-item-price">Ціна: 175₴</p>
              <button onClick={() => handleShowPopUp(3)}>Детальніше</button>
            </div>
          </div>
        </Col>
      </Row>

      {/* Попап компонент */}
      {showPopUp === 1 && <PopUp1 show={true} handleClose={handleClosePopUp} />}
      {showPopUp === 2 && <PopUp2 show={true} handleClose={handleClosePopUp} />}
      {showPopUp === 3 && <PopUp3 show={true} handleClose={handleClosePopUp} />}
    </Container>
  );
};

export default MainCatalog;
