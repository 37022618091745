// PopUp1.js
import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/PopUp1.css"; // Импортируем стили для попапа
import PopUpImg3 from "../Images/PopUpImg3.png";

const PopUp3 = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      className="custom-popup"
      dialogClassName="custom-popup-dialog"
    >
      <Modal.Body className="popup-body p-4">
        <Button
          variant="close"
          onClick={handleClose}
          aria-label="Close"
          className="close-button position-absolute top-0 end-0 m-3"
        />
        <div className="popup-content row">
          {/* Левая колонка с изображением */}
          <div className="popup-image col-md-6 mb-3 mb-md-0">
            <img src={PopUpImg3} className="img-fluid" alt="Рубашка 'Модерн'" />
          </div>
          {/* Правая колонка с текстом */}
          <div className="popup-text col-md-6">
            <h2 className="popup-title mb-3">
              Футболка "<span className="popup-title-word">Модерн</span>"
            </h2>
            <p className="popup-description">
              Зручна футболка з якісного бавовняного матеріалу, ідеальна для
              щоденного носіння. Легка та дихаюча, вона додає стильності
              будь-якому образу. Доступна в різних кольорах і з оригінальними
              принтами.
            </p>
            <div className="popup-info row mt-4">
              <div className="info-item col-6">
                <h5 className="info-number">01</h5>
                <p className="info-text">
                  РФутболки з'явилися як нижня білизна у 19 столітті.
                </p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">02</h5>
                <p className="info-text">
                  Біла футболка — базовий елемент гардеробу.
                </p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">03</h5>
                <p className="info-text">Часто мають різноманітні малюнки.</p>
              </div>
              <div className="info-item col-6">
                <h5 className="info-number">04</h5>
                <p className="info-text">
                  Моделі можуть мати властивості для запобігання поту.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopUp3;
