import React from "react";
import "../Styles/Footer.css";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="footer py-3">
      <Container>
        <Row className="text-center">
          {/* 3 пункта на одной строке с минимальными отступами */}
          <Col xs={12} md={4} className="px-0">
            <a href="#home" className="footer-link">
              Згода на обробку даних
            </a>
          </Col>
          <Col xs={12} md={4} className="px-0">
            <a href="#services" className="footer-link">
              Служба підтримки
            </a>
          </Col>
          <Col xs={12} md={4} className="px-0">
            <a href="#contact" className="footer-link">
              Політика конфіденційності
            </a>
          </Col>
        </Row>
        {/* Пункт ниже по центру */}
        <Row className="text-center mt-2">
          <Col xs={12}>
            <a href="#privacy" className="footer-link">
              © fashionfusion.com.ua, 2024 Fashion Fusion
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
